/**
 * get Piano Browser analytics (cxense) and add to Digital Audience analytics
 */
export default () => {

  try {
    function checkScriptsLoaded() {
      return window.cX && window.cX.getUserId && window.__da;
    }

    let iterations = 0;
    const maxAttempts = 20;
    const intervalTime = 100; // milliseconden

    if (checkScriptsLoaded()) {
      startFunction();
    } else {

      const interval = setInterval(() => {
        if (checkScriptsLoaded()) {
          clearInterval(interval);
          startFunction();
          return;
        }

        iterations++;
        if (iterations >= maxAttempts) {
          clearInterval(interval);
          console.error("Timeout: Scripts niet gevonden binnen de limiet.");
        }
      }, intervalTime);
    }

    function startFunction() {
      // console.log('started script in iterations: ', iterations)
      // console.log("Beide scripts zijn geladen!");
      const ppoid = window.cX.getUserId(true);
      window.__da.bake({partner: 'po_241126', parcid: ppoid, publisher: 'dap_241126', syncs: [], dpixel: '2626'});
    }

  }catch (e) {
    console.error(e)
  }
}