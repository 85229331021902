import '../css/app.scss';
// import './bootstrap';
import "@/helpers/forceExternalNewWindow";
import { createInertiaApp } from '@inertiajs/react';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { createRoot, hydrateRoot } from 'react-dom/client';
import Providers from "@/providers";
import useGdprCookie from "@/helpers/useGdprCookie";
import useDigitalAudience from "@/helpers/useDigitalAudience";

import moment from "moment-timezone";
import 'moment/dist/locale/nl';
moment.locale('nl');
moment.tz.setDefault("Europe/Amsterdam");

// import axios and add default headers
import axios, {AxiosInstance} from 'axios';
window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.headers.common['X-CSRFToken'] = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content');


// Extend window with custom attributes
declare global {
  interface Window {
    grecaptcha: any; // google recaptcha
    KaChing: any; // Ads
    axios: AxiosInstance, // axios with csrf token
    _comscore: object[], //NMO Comscore (ScorecardResearch)
    gdprAppliesGlobally: boolean,
    harvest: any,
    Didomi: any,
    cX: any  // Piano Browser analytics
    __da: any // Digital Audience analytics

  }
}

useGdprCookie()
useDigitalAudience()


createInertiaApp({
  resolve: (name) =>
    resolvePageComponent(
      `./Pages/${name}.tsx`,
      import.meta.glob('./Pages/**/*.tsx'),
    ),
  setup({ el, App, props }) {
    const initProps = props.initialPage.props as any
    const AppContainer = <Providers legacyWidget={false} props={initProps}><App {...props} /></Providers>;

    if (import.meta.env.SSR) {
      hydrateRoot(el, AppContainer);
      return;
    }

    createRoot(el).render(AppContainer);
  },
  progress: {
    color: '#4B5563',
  },
});
